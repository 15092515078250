



























































import 'vue-material-design-icons/styles.css'
import Ribbon from 'vue-ribbon'
import LeftIcon from 'vue-material-design-icons/ChevronLeftCircle'
import RightIcon from 'vue-material-design-icons/ChevronRightCircle'
import PlusIcon from 'vue-material-design-icons/PlusCircle'
import MinusIcon from 'vue-material-design-icons/MinusCircle'
import Flipbook from './Flipbook'

export default
  name: 'app'
  components: { Flipbook, LeftIcon, RightIcon, PlusIcon, MinusIcon, Ribbon }
  data: ->
    pages: [],
    pagesHiRes: [],
    hasMouse: true
    pageNum: null
  methods:
    onFlipLeftStart: (page) -> console.log 'flip-left-start', page
    onFlipLeftEnd: (page) ->
      console.log 'flip-left-end', page
      window.location.hash = '#' + page
    onFlipRightStart: (page) -> console.log 'flip-right-start', page
    onFlipRightEnd: (page) ->
      console.log 'flip-right-end', page
      window.location.hash = '#' + page
    onZoomStart: (zoom) -> console.log 'zoom-start', zoom
    onZoomEnd: (zoom) -> console.log 'zoom-end', zoom
    setPageFromHash: ->
      n = parseInt window.location.hash.slice(1), 10
      @pageNum = n if isFinite n
  mounted: ->
    window.addEventListener 'keydown', (ev) =>
      flipbook = @$refs.flipbook
      return unless flipbook
      flipbook.flipLeft() if ev.keyCode == 37 and flipbook.canFlipLeft
      flipbook.flipRight() if ev.keyCode == 39 and flipbook.canFlipRight

    # Simulate asynchronous pages initialization
    setTimeout (=>
      @pages = [
        null
        'images/01.jpg'
        'images/02.jpg'
        'images/03.jpg'
        'images/04.jpg'
        'images/05.jpg'
        'images/06.jpg'
        'images/07.jpg'
        'images/08.jpg'
        'images/09.jpg'
        'images/10.jpg'
        'images/11.jpg'
        'images/12.jpg'
        'images/13.jpg'
        'images/14.jpg'
      ]
      @pagesHiRes = [
        null
        'images-large/01.jpg'
        'images-large/02.jpg'
        'images-large/03.jpg'
        'images-large/04.jpg'
        'images-large/05.jpg'
        'images-large/06.jpg'
        'images-large/07.jpg'
        'images-large/08.jpg'
        'images-large/09.jpg'
        'images-large/10.jpg'
        'images-large/11.jpg'
        'images-large/12.jpg'
        'images-large/13.jpg'
        'images-large/14.jpg'
      ]
    ), 1

    window.addEventListener 'hashchange', @setPageFromHash
    @setPageFromHash()
